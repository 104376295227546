import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import HubspotForm from 'react-hubspot-form'
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import { useShowConsentForm } from '../../context/ConsentContext'

import Text from '../common/Text'
import { theme } from '../../styles'
import { trackForm } from './hubspot/HubSpotFormAPI'
import HubspotStyles from './hubspot/hs-form-styles'
import { trackEvent, identify } from '../../utils/eventTrack'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 80px;
  width: 100%;
  align-items: center;

  .custom-hs-css {
    ${props => props.showConsentForm && `display: flex;`}
    ${props => props.showConsentForm && `flex-direction: column;`}
    ${props => !props.showConsentForm && `display: grid;`}
    ${props => !props.showConsentForm && `grid-template-columns: 2fr 1fr;`}
    ${props => !props.showConsentForm && `align-items: flex-end;`}

    .hs_submit {
      ${props => props.showConsentForm && `margin-top: 32px;`}
    }

    .hs-form-field.hs-email {
      margin-bottom: 0px;
    }

    input.hs-input {
      height: 44px;
      grid-column: 1 / 3;
      border: 2px solid ${theme.color.purple500};
      border-radius: ${props => (props.showConsentForm ? '8px' : '8px 0px 0px 8px')};
    }

    input.custom-hs-button {
      padding: unset;
      height: 44px;
      font-size: ${theme.fonts.size.smallBodyBold};
      margin: 0px;
      align-self: bottom;
      width: 100%;
      border-radius: ${props => (props.showConsentForm ? '8px' : '0px 8px 8px 0px')};
      grid-column: 3 / 4;

      &:hover,
      &:focus {
        padding: unset;
      }
    }

    .hs-error-msgs {
      position: absolute;
    }
    .hs_error_rollup {
      display: none;
    }
  }

  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`

const TextWrapper = styled.div`
  text-align: right;

  @media (max-width: 1050px) {
    text-align: center;
  }
`

const NewsletterSubscribe = () => {
  const [formLoaded, setFormLoaded] = useState(false)
  const { showConsentForm, showConsentFormLoading } = useShowConsentForm()
  const stagingHubSpotFormId = '354742cf-bf4b-46dd-83ad-12f0f6010190'
  const productionHubSpotFormId = '1c8cc91b-cf6b-4d63-8a9f-ea05d1b3299a'
  const stagingHubSpotFormIdConsentRequired = 'a6bdea2f-53bf-4cd7-9a07-4dab02d7574a'
  const productionHubSpotFormIdConsentRequired = '4f670fd1-ffa0-4a16-a470-611eef603540'

  let finalFormId

  if (process.env.GATSBY_ACTIVE_ENV === 'production') {
    finalFormId = showConsentForm ? productionHubSpotFormIdConsentRequired : productionHubSpotFormId
  } else {
    finalFormId = showConsentForm ? stagingHubSpotFormIdConsentRequired : stagingHubSpotFormId
  }

  function formSubmitHandler(e) {
    if (e.data.type === 'hsFormCallback' && e.data.eventName === 'onFormFailedValidation') {
      let errors = null
      if (Array.isArray(e.data.data)) {
        errors = e.data.data.filter(error => {
          if (error.errorTypes.length) {
            return error
          }
          return null
        })
      }

      if (errors) {
        errors.forEach(error => {
          const fieldLabel = document.querySelector(
            `.hs-form-lander div.hs-form-field.hs_${error?.name} > label > span`
          )?.textContent
          const fieldWrapper = document.querySelector(
            `.hs-form-lander div.hs-form-field.hs_${error?.name} label.hs-error-msg`
          )
          if (fieldWrapper && fieldLabel) {
            fieldWrapper.textContent = `Please complete the ${fieldLabel.toLowerCase()} field.`
          }
        })

        const firstErr = errors[0]?.name
        if (firstErr) {
          const toFocus = document.querySelector(`.hs-form-lander .hs-input[name=${firstErr}]`)
          if (toFocus) toFocus.focus()
        }
      }
    }
    if (e.data.type === 'hsFormCallback' && e.data.eventName === 'onFormSubmit') {
      if (
        e.data.id === stagingHubSpotFormId ||
        e.data.id === productionHubSpotFormId ||
        e.data.id === stagingHubSpotFormIdConsentRequired ||
        e.data.id === productionHubSpotFormIdConsentRequired
      ) {
        trackForm(e.data.data, finalFormId)

        const mappedData = mapValues(keyBy(e.data.data, 'name'), 'value')
        identify(mappedData)
      }
    }
  }

  useEffect(() => {
    if (formLoaded) {
      const persistedUtmParams = JSON.parse(sessionStorage.getItem('utm_params'))
      if (persistedUtmParams) {
        Object.entries(persistedUtmParams).forEach(param => {
          const hiddenInput = document.querySelector(`input[name="${param[0]}"]`)
          if (hiddenInput) hiddenInput.value = param[1] || ''
        })
      }

      const persistedGclidParam = JSON.parse(sessionStorage.getItem('gclid_param'))
      const gclidHiddenInput = document.querySelector('input[name="gclid__c"]')
      if (gclidHiddenInput) gclidHiddenInput.value = persistedGclidParam || ''

      const mutinyExperienceName = localStorage.getItem('mutiny_experience_name')
      const mutinySegmentName = localStorage.getItem('mutiny_segment_name')
      const mutinyVariationName = localStorage.getItem('mutiny_variation_name')

      const mutinyExperienceNameInput = document.querySelector(`input[name="mutiny_experience_name"]`)
      if (mutinyExperienceNameInput && mutinyExperienceName) mutinyExperienceNameInput.value = mutinyExperienceName

      const mutinySegmentNameInput = document.querySelector(`input[name="mutiny_segment_name"]`)
      if (mutinySegmentNameInput && mutinySegmentName) mutinySegmentNameInput.value = mutinySegmentName

      const mutinyVariationNameInput = document.querySelector(`input[name="mutiny_variation_name"]`)
      if (mutinyVariationNameInput && mutinyVariationName) mutinyVariationNameInput.value = mutinyVariationName
    }
  }, [formLoaded])

  useEffect(() => {
    window.addEventListener('message', formSubmitHandler)
    return () => {
      window.removeEventListener('message', formSubmitHandler)
    }
  }, [])

  return (
    <Wrapper css={HubspotStyles} showConsentForm={showConsentForm}>
      <TextWrapper>
        <Text heading3>Subscribe for the latest accessibility news and resources</Text>
      </TextWrapper>
      {!showConsentFormLoading && (
        <HubspotForm
          portalId={process.env.GATSBY_HUBSPOT_PORTAL_ID}
          formId={finalFormId}
          loading={<div>Loading...</div>}
          onReady={() => {
            setFormLoaded(true)
            trackEvent('Newsletter Subscription Form Viewed')
          }}
          onFormReady={() => {
            const formInputs = document.querySelectorAll(`#hsForm_${finalFormId} input`)
            formInputs.forEach(input => {
              input.addEventListener('change', e => {
                trackEvent(`Form Value Changed`, {
                  'Form Hubspot ID': finalFormId,
                  'Form Element Name': e.target.name,
                  'Form Element Type': 'input',
                  'New Value': e.target.value,
                })
              })

              input.addEventListener('focus', e => {
                trackEvent(`Form Value Focused`, {
                  'Form Hubspot ID': finalFormId,
                  'Form Element Name': e.target.name,
                  'Form Element Type': 'input',
                  'Current Value': e.target.value,
                })
              })
            })
          }}
          submitButtonClass="custom-hs-button"
          cssClass="custom-hs-css hs-form-lander"
        />
      )}
    </Wrapper>
  )
}
export default NewsletterSubscribe
